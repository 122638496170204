import React from "react";
import useStyles from "./styles";
import {
  FacebookOutlined,
  Instagram,
  Mail,
  Phone,
  Policy,
  WhatsApp,
} from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
export const Footer = () => {
  const classes = useStyles();
  return (
    <div style={{ borderTop: "1px solid #bcab79" }}>
      <div className={classes.backgroundDarkHolder}>
        <div className={classes.columnHolder}>
          <h2>Contact</h2>
          <br />
          <IconButton style={{ color: "#bcab79" }}>
            <Mail />
          </IconButton>
          contact@vinshop.ro
          <br />
          <IconButton style={{ color: "#bcab79" }}>
            <Phone />
          </IconButton>
          <a
            style={{ color: "inherit", textDecoration: "inherit" }}
            href="tel: +40 745 629 362"
          >
            +40 745 629 362
          </a>{" "}
          - Cluj
          <br />
          <IconButton style={{ color: "#bcab79" }}>
            <Phone />
          </IconButton>
          <a
            style={{ color: "inherit", textDecoration: "inherit" }}
            href="tel: +40 754 967 211"
          >
            +40 754 967 211
          </a>{" "}
          - Maramureș
          <br />
        </div>
        <div className={classes.columnHolder}>
          <h2>Ne găsești și pe:</h2>
          <br></br>
          <IconButton
            style={{ color: "#bcab79" }}
            href="https://www.instagram.com/prosecco.shop/"
            target="_new"
          >
            <Instagram />
          </IconButton>
          <br></br>
          <IconButton
            style={{ color: "#bcab79" }}
            href="https://wa.me/+40754967211"
            target="_new"
          >
            <WhatsApp />
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#bcab79",
                cursor: "pointer",
              }}
            />
          </IconButton>
          <br></br>
          <IconButton
            style={{ color: "#bcab79" }}
            href="https://www.facebook.com/profile.php?id=100094232318629"
            target="_new"
          >
            <FacebookOutlined />
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#bcab79",
                cursor: "pointer",
              }}
            ></Typography>
          </IconButton>
          <br></br>
        </div>

        <div className={classes.columnHolder}>
          <h2>Linkuri Utile</h2>
          <br></br>
          <IconButton
            style={{ color: "#bcab79" }}
            href="/TERMENI SI CONDITII.pdf"
            target="_new"
          >
            <Policy />{" "}
          </IconButton>
          Termeni și condiții<br></br>
          <IconButton
            style={{ color: "#bcab79" }}
            href="/POLITICA DE CONFIDENTIALITATE.pdf"
            target="_new"
          >
            <Policy />{" "}
          </IconButton>
          Politica de confidențialitate<br></br>
          <IconButton
            style={{ color: "#bcab79" }}
            href="/POLITICA DE LIVRARE.pdf"
            target="_new"
          >
            <Policy />
          </IconButton>
          Politica de livrare<br></br>
          <IconButton
            style={{ color: "#bcab79" }}
            href="/POLITICA DE RETUR.pdf"
            target="_new"
          >
            <Policy />
          </IconButton>
          Politica de retur<br></br>
          <IconButton
            style={{ color: "#bcab79" }}
            href="/GDPR.pdf"
            target="_new"
          >
            <Policy></Policy>
          </IconButton>
          GDPR<br></br>
        </div>

        <div className={classes.columnHolder}>
          <img
            className={classes.imgLogo}
            src={process.env.PUBLIC_URL + "/logo192.avif"}
            alt="Logo"
          />
          <div className={classes.brandHolder}>Anserini Drinks S.R.L.</div>
          <div className={classes.brandHolder}>CUI 47923878</div>
          <img></img>
        </div>
      </div>
      <div className={classes.backgroundDarkHolder}>
        <div className={classes.columnHolder}>
          <a href="https://anpc.ro/ce-este-sal/" target="_new">
            <img
              style={{ width: "50%" }}
              src={`${process.env.PUBLIC_URL}/SAL.svg`}
            ></img>
          </a>
          <br />
          <a href="https://ec.europa.eu/consumers/odr" target="_new">
            <img
              style={{ width: "50%", height: "50%" }}
              src={`${process.env.PUBLIC_URL}/SOL.svg`}
            ></img>
          </a>
        </div>

        <div className={classes.columnHolder} />
        <div className={classes.columnHolder}>
          <a
            href="https://www.firmadeincredere.ro/company,64253,anserini-drinks-srl"
            target="_new"
          >
            <img
              style={{ width: "50%" }}
              src={`${process.env.PUBLIC_URL}/trust.avif`}
            ></img>
          </a>
        </div>
        <div className={classes.columnHolder}>
          <br />
          <a href="https://netopia-payments.com/" target="_new">
            <img
              style={{ width: "50%" }}
              src={`${process.env.PUBLIC_URL}/NETOPIA.svg`}
            ></img>
          </a>
        </div>
      </div>
      <br />
    </div>
  );
};
