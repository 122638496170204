import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { serverUrl } from "../../consts/serverUrl";
import { useNavigate } from "react-router-dom";
import {
  formDataCheck,
  checkEmptyDictionary,
  countySelector,
  removeDiacritics,
} from "../../consts/helpers";
import {
  emailRegexTest,
  romanianPhoneNumberRegexTest,
} from "../../consts/functions";
import { Button, Divider, Input } from "@mui/material";
import { Cash, CreditCard, ReceiptSVG, ShippingSVG } from "../svgs/UtilSVGs";
import {
  cashOnDeliveryPrice,
  shippingPrice,
  shippingThreshold,
} from "../../consts/shipping";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../contexts/AuthProvider";
import { PSDark, PSRed } from "../../consts/styleConsts";
import { SpinnerBody } from "../Spinner/Spinner";

export const Shipping = (props) => {
  const {
    user,
    token,
    userCart,
    setUserCart,
    getUserId,
    setIsRedirectingToPayment,
  } = useAuth();
  const {
    page,
    setPage,
    products,
    userInfo,
    navbarHeight,
    discountPercentage,
    setPreviousPage,
  } = props;
  const [sameInvoiceData, setSameInvoiceData] = useState(true);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [savedDetails, setSavedDetails] = useState(false);
  const [validationError, setValidationError] = useState(false);

  // Inputs
  const [shippingCounty, setShippingCounty] = useState("0");
  const [billingCounty, setBillingCounty] = useState("0");
  const [shippingCity, setShippingCity] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [shippingAddressLine, setShippingAddressLine] = useState("");
  const [billingAddressLine, setBillingAddressLine] = useState("");
  const [shippingCompanyName, setShippingCompanyName] = useState("");
  const [billingCompanyName, setBillingCompanyName] = useState("");
  const [shippingCIF, setShippingCIF] = useState("");
  const [billingCIF, setBillingCIF] = useState("");
  const [shippingFirstName, setShippingFirstName] = useState("");
  const [billingFirstName, setBillingFirstName] = useState("");
  const [shippingLastName, setShippingLastName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [shippingEmail, setShippingEmail] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [shippingMobilePhone, setShippingMobilePhone] = useState("");
  const [billingMobilePhone, setBillingMobilePhone] = useState("");
  const [shippingCountry, setShippingCountry] = useState("Romania");
  const [billingCountry, setBillingCountry] = useState("Romania");

  const navigate = useNavigate();
  const classes = useStyles();

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  useEffect(() => {
    setIsRedirectingToPayment(false);
  });

  useEffect(() => {
    // @ts-ignore
    if (user && getUserId() !== "waiting" && getUserId() !== "guest") {
      // @ts-ignore
      if (Object.keys(user.shippingDetails).length > 0) {
        setSavedDetails(true);
        // @ts-ignore
        setShippingCounty(user.shippingDetails.shippingCounty || "0");
        // @ts-ignore
        setShippingCity(user.shippingDetails.shippingCity || "");
        // @ts-ignore
        setShippingAddressLine(user.shippingDetails.shippingAddressLine || "");
        // @ts-ignore
        setShippingCompanyName(user.shippingDetails.shippingCompanyName || "");
        // @ts-ignore
        setShippingCIF(user.shippingDetails.shippingCIF || "");
        // @ts-ignore
        setShippingFirstName(user.shippingDetails.shippingFirstName || "");
        // @ts-ignore
        setShippingLastName(user.shippingDetails.shippingLastName || "");
        // @ts-ignore
        setShippingMobilePhone(user.shippingDetails.shippingMobilePhone || "");

        // @ts-ignore
        setBillingCounty(user.billingDetails.billingCounty || "0");
        // @ts-ignore
        setBillingCity(user.billingDetails.billingCity || "");
        // @ts-ignore
        setBillingAddressLine(user.billingDetails.billingAddressLine || "");
        // @ts-ignore
        setBillingCompanyName(user.billingDetails.billingCompanyName || "");
        // @ts-ignore
        setBillingCIF(user.billingDetails.billingCIF || "");
        // @ts-ignore
        setBillingFirstName(user.billingDetails.billingFirstName || "");
        // @ts-ignore
        setBillingLastName(user.billingDetails.billingLastName || "");
        // @ts-ignore
        setBillingMobilePhone(user.billingDetails.billingMobilePhone || "");
      }

      setShippingEmail(getUserId());
      setBillingEmail(getUserId());
    }
  }, [user, getUserId]);

  useEffect(() => {
    setPreviousPage("");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const onClickContinueShopping = () => {
    setPage("Shop");
    navigate("/shop");
  };

  const onClickCheckout = () => {
    setContinueButtonDisabled(true);
    headToPayment();
  };

  const getNextOrderId = async () => {
    const nextOrderId = await fetch(`${serverUrl}/order`).then((res) =>
      res.json()
    );
    return nextOrderId;
  };

  const getPaymentRequest = async (orderId, price, shippingIncluded) => {
    let missingFields,
      wrongEmail,
      wrongPhone = false;

    let requestData = {};

    const customerType =
      shippingCIF !== "" || billingCIF !== "" ? "company" : "person";
    const shippingCountryReadable = removeDiacritics(shippingCountry);
    const shippingCityReadable = removeDiacritics(shippingCity);
    const shippingAddressLineReadable = removeDiacritics(shippingAddressLine);
    const shippingFirstNameReadable = removeDiacritics(shippingFirstName);
    const shippingLastNameReadable = removeDiacritics(shippingLastName);
    const shippingAddress = `${shippingCountryReadable}, jud. ${shippingCounty}, localitate ${shippingCityReadable}, ${shippingAddressLineReadable}`;
    const shippingEmailReadable = removeDiacritics(shippingEmail);

    formDataCheck(
      "shipping",
      shippingCountryReadable,
      shippingCounty,
      shippingCityReadable,
      shippingAddressLineReadable,
      shippingEmailReadable,
      shippingFirstNameReadable,
      shippingLastNameReadable,
      shippingMobilePhone,
      classes
    );

    let billingCityReadable = "";
    let billingCountryReadable = "";
    let billingAddressLineReadable = "";

    if (
      !shippingFirstNameReadable ||
      !shippingLastNameReadable ||
      !shippingEmailReadable ||
      !shippingMobilePhone ||
      !shippingCountryReadable ||
      !shippingCityReadable ||
      !shippingAddressLineReadable ||
      shippingCounty === "0"
    ) {
      missingFields = true;
      setValidationError(true);
    } else if (!romanianPhoneNumberRegexTest(shippingMobilePhone)) {
      wrongPhone = true;
    } else if (!emailRegexTest(shippingEmailReadable)) {
      wrongEmail = true;
    } else if (!sameInvoiceData) {
      billingCountryReadable = removeDiacritics(billingCountry);
      billingCityReadable = removeDiacritics(billingCity);
      billingAddressLineReadable = removeDiacritics(billingAddressLine);

      const billingFirstNameReadable = removeDiacritics(billingFirstName);
      const billingLastNameReadable = removeDiacritics(billingLastName);
      const billingAddress = `${billingCountryReadable}, jud. ${billingCounty}, localitate ${billingCityReadable}, ${billingAddressLineReadable}`;
      // const billingEmail = document.getElementById("billingEmailInput")?.value;
      // const billingMobilePhone =
      //   document.getElementById("billingPhoneInput")?.value;
      // const billingCIF = document.getElementById("billingCIF")?.value;
      // const billingCompanyName =
      //   document.getElementById("billingCompanyName")?.value;
      formDataCheck(
        "billing",
        billingCountryReadable,
        billingCounty,
        billingCityReadable,
        billingAddressLineReadable,
        billingEmail,
        billingFirstNameReadable,
        billingLastNameReadable,
        billingMobilePhone,
        classes
      );

      if (
        !billingFirstNameReadable ||
        !billingLastNameReadable ||
        !billingEmail ||
        !billingMobilePhone ||
        !billingCountryReadable ||
        !billingCityReadable ||
        !billingAddressLineReadable ||
        billingCounty === "0"
      ) {
        missingFields = true;
        setValidationError(true);
      } else if (!romanianPhoneNumberRegexTest(billingMobilePhone)) {
        wrongPhone = true;
      } else if (!emailRegexTest(billingEmail)) {
        wrongEmail = true;
      }
      requestData = {
        cart: userCart[getUserId()],
        customerType: customerType,
        shippingCNP: "",
        shippingFirstName: shippingFirstNameReadable,
        shippingLastName: shippingLastNameReadable,
        shippingAddress: shippingAddress,
        shippingEmail: shippingEmailReadable,
        shippingMobilePhone: shippingMobilePhone,
        shippingCIF: shippingCIF,
        shippingCompanyName: shippingCompanyName,
        billingCNP: "",
        billingFirstName: billingFirstNameReadable,
        billingLastName: billingLastNameReadable,
        billingAddress: billingAddress,
        billingEmail: billingEmail,
        billingMobilePhone: billingMobilePhone,
        billingCIF: billingCIF,
        billingCompanyName: billingCompanyName,
        orderId: orderId,
        price: parseFloat(price).toFixed(2),
        shippingIncluded: shippingIncluded,
        processingPrice: paymentMethod === "cash" ? cashOnDeliveryPrice : 0,
      };
    } else {
      requestData = {
        cart: userCart[getUserId()],
        customerType: customerType,
        shippingCNP: "",
        shippingFirstName: shippingFirstNameReadable,
        shippingLastName: shippingLastNameReadable,
        shippingAddress: shippingAddress,
        shippingEmail: shippingEmailReadable,
        shippingMobilePhone: shippingMobilePhone,
        shippingCIF: shippingCIF,
        shippingCompanyName: shippingCompanyName,
        billingCNP: "",
        billingFirstName: shippingFirstNameReadable,
        billingLastName: shippingLastNameReadable,
        billingAddress: shippingAddress,
        billingEmail: shippingEmailReadable,
        billingMobilePhone: shippingMobilePhone,
        billingCIF: shippingCIF,
        billingCompanyName: shippingCompanyName,
        orderId: orderId,
        price: parseFloat(price).toFixed(2),
        shippingIncluded: shippingIncluded,
        processingPrice: paymentMethod === "cash" ? cashOnDeliveryPrice : 0,
      };
    }
    if (missingFields) {
      // TODO: Uniformize validation messages across the app
      alert("Va rugam sa completati toate campurile obligatorii!");
      setContinueButtonDisabled(false);
      return;
    } else if (wrongPhone) {
      setValidationError(true);
      alert("Va rugam introduceti un numar de telefon valid!");
      setContinueButtonDisabled(false);
      return;
    } else if (wrongEmail) {
      setValidationError(true);
      alert("Va rugam introduceti o adresa de mail valida!");
      setContinueButtonDisabled(false);
      return;
    } else if (!agreeTerms) {
      setValidationError(true);
      alert(
        "Pentru a plasa comanda trebuie să fiți de acord cu termenii și condițiile!"
      );
      setContinueButtonDisabled(false);
    }

    setValidationError(false);
    // @ts-ignore
    if (getUserId() !== "waiting" && getUserId() !== "guest") {
      if (!token) return;

      const userDetailsReqBody = {
        shippingDetails: {
          shippingFirstName: requestData.shippingFirstName,
          shippingLastName: requestData.shippingLastName,
          shippingAddress: requestData.shippingAddress,
          shippingEmail: requestData.shippingEmail,
          shippingMobilePhone: requestData.shippingMobilePhone,
          shippingCIF: requestData.shippingCIF,
          shippingCompanyName: requestData.shippingCompanyName,
          shippingAddressLine: shippingAddressLineReadable,
          shippingCity: shippingCityReadable,
          shippingCounty: shippingCounty,
          shippingCountry: shippingCountryReadable,
        },
        billingDetails: {
          billingFirstName: requestData.billingFirstName,
          billingLastName: requestData.billingLastName,
          billingAddress: requestData.billingAddress,
          billingEmail: requestData.billingEmail,
          billingMobilePhone: requestData.billingMobilePhone,
          billingCIF: requestData.billingCIF,
          billingCompanyName: requestData.billingCompanyName,
          billingAddressLine: sameInvoiceData
            ? shippingAddressLineReadable
            : billingAddressLineReadable,
          billingCity: sameInvoiceData
            ? shippingCityReadable
            : billingCityReadable,
          billingCounty: sameInvoiceData ? shippingCounty : billingCounty,
          billingCountry: sameInvoiceData
            ? shippingCountryReadable
            : billingCountryReadable,
        },
      };

      try {
        await fetch(`${serverUrl}/save-user-order-details`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userDetailsReqBody),
        }).then((res) => res.json());
      } catch (error) {
        console.error("Error:", error);
      }
    }

    setLoadingPayment(true);
    if (paymentMethod === "card") {
      setIsRedirectingToPayment(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      };

      try {
        const paymentRequest = await fetch(
          `${serverUrl}/order-request`,
          requestOptions
        ).then((res) => res.json());
        return paymentRequest;
      } catch (error) {
        console.error("Error:", error);
      }
    } else if (paymentMethod === "cash") {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      };

      try {
        const saveOrder = await fetch(
          `${serverUrl}/save-cash-order`,
          requestOptions
        ).then((res) => res.json());
        if (saveOrder.message === "Success") {
          return "success";
        } else return "error";
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const headToPayment = async () => {
    const nextOrderId = await getNextOrderId();
    const paymentRequest = await getPaymentRequest(
      nextOrderId,
      (calculateTotalPrice() * (100 - discountPercentage)) / 100 +
        calculateShippingPrice(
          (calculateTotalPrice() * (100 - discountPercentage)) / 100
        ),
      calculateShippingPrice(
        (calculateTotalPrice() * (100 - discountPercentage)) / 100
      ) !== 0
        ? false
        : true
    );
    if ((await paymentRequest) === "success" && paymentMethod === "cash") {
      navigate(`/payment-confirmation/${nextOrderId}`);
    } else if (paymentMethod === "card") {
      // Create a form element
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "https://secure.mobilpay.ro";

      const envKeyInput = document.createElement("input");
      envKeyInput.type = "hidden";
      envKeyInput.name = "env_key";
      envKeyInput.value = paymentRequest.env_key;
      form.appendChild(envKeyInput);

      // Add data as a hidden input field
      const dataInput = document.createElement("input");
      dataInput.type = "hidden";
      dataInput.name = "data";
      dataInput.value = paymentRequest.data;
      form.appendChild(dataInput);

      const cipherInput = document.createElement("input");
      cipherInput.type = "hidden";
      cipherInput.name = "cipher";
      cipherInput.value = paymentRequest.cipher;
      form.appendChild(cipherInput);

      const ivInput = document.createElement("input");
      ivInput.type = "hidden";
      ivInput.name = "iv";
      ivInput.value = paymentRequest.iv;
      form.appendChild(ivInput);

      // Append the form to the document body
      document.body.appendChild(form);

      // Submit the form
      form.submit();
    }
  };

  const returnUserInfoParam = (param) => {
    if (userInfo[param]) return userInfo[param];
    return "";
  };

  const returnUserNestedParam = (user, param, nestedParam) => {
    if (user[param] && user[param][nestedParam])
      return user[param][nestedParam];
    return "";
  };

  const calculateShippingPrice = (orderPrice) => {
    if (orderPrice < shippingThreshold) return shippingPrice;
    else return 0;
  };
  const calculateTotalPrice = () => {
    let totalPriceLocal = 0;
    if (!checkEmptyDictionary(userCart))
      Object.keys(userCart[getUserId()].line_items).map((productId) => {
        totalPriceLocal +=
          (findProduct(productId, products)
            ? findProduct(productId, products).discount
              ? (parseFloat(findProduct(productId, products).price) *
                  (100.0 -
                    parseFloat(findProduct(productId, products).discount))) /
                100.0
              : parseFloat(findProduct(productId, products).price)
            : 0) * userCart[getUserId()].line_items[productId];
      });

    return totalPriceLocal;
  };
  const findProduct = (productId, allProducts) => {
    return allProducts.find((product) => product.productId === productId);
  };

  useEffect(() => {
    // TODO: update to use provider functions everywhere it is used
    // setCart(retrieveCart());
  }, [setUserCart]);

  const shippingDetails = (shippingOrBilling) => {
    // Utilities for validation
    const isCityEmpty =
      shippingOrBilling === "shipping"
        ? shippingCity === ""
        : billingCity === "";
    const isCountyEmpty =
      shippingOrBilling === "shipping"
        ? shippingCounty === "0"
        : billingCounty === "0";
    const isAddressEmpty =
      shippingOrBilling === "shipping"
        ? shippingAddressLine === ""
        : billingAddressLine === "";

    return (
      <div>
        <br />
        <div>Tara*</div>
        <Input
          style={{
            border: `1px solid ${PSDark}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSDark,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          id={`${shippingOrBilling}Country`}
          type="text"
          value="Romania"
          readOnly={true}
          onChange={(_) => {
            // Only shipping to Romania. Do nothing
          }}
        ></Input>
        <br />
        <br />
        <div>Judet*</div>
        {countySelector(
          shippingOrBilling,
          shippingOrBilling === "shipping" ? shippingCounty : billingCounty,
          shippingOrBilling === "shipping"
            ? setShippingCounty
            : setBillingCounty,
          validationError && isCountyEmpty
        )}
        <br />
        <br />
        <div>Localitate*</div>
        <Input
          style={{
            border:
              validationError && isCityEmpty
                ? `1px solid ${PSRed}`
                : `1px solid ${PSDark}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSDark,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          id={`${shippingOrBilling}City`}
          type="text"
          value={shippingOrBilling === "shipping" ? shippingCity : billingCity}
          onChange={(e) => {
            shippingOrBilling === "shipping"
              ? setShippingCity(e.target.value)
              : setBillingCity(e.target.value);
          }}
        ></Input>
        <br /> <br />
        <div>Adresa*</div>
        <Input
          style={{
            border:
              validationError && isAddressEmpty
                ? `1px solid ${PSRed}`
                : `1px solid ${PSDark}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSDark,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          id={`${shippingOrBilling}Address`}
          type="text"
          placeholder="strada, nr., etaj, ap. etc."
          value={
            shippingOrBilling === "shipping"
              ? shippingAddressLine
              : billingAddressLine
          }
          onChange={(e) => {
            shippingOrBilling === "shipping"
              ? setShippingAddressLine(e.target.value)
              : setBillingAddressLine(e.target.value);
          }}
        ></Input>
        <br /> <br />
        <div>Nume companie</div>
        <Input
          style={{
            border: `1px solid ${PSDark}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSDark,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          id={`${shippingOrBilling}CompanyName`}
          type="text"
          placeholder="pentru pers. juridice"
          value={
            shippingOrBilling === "shipping"
              ? shippingCompanyName
              : billingCompanyName
          }
          onChange={(e) => {
            shippingOrBilling === "shipping"
              ? setShippingCompanyName(e.target.value)
              : setBillingCompanyName(e.target.value);
          }}
        ></Input>
        <br /> <br />
        <div>CUI/CIF</div>
        <Input
          style={{
            border: `1px solid ${PSDark}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSDark,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          id={`${shippingOrBilling}CIF`}
          type="text"
          placeholder="pentru pers. juridice"
          value={shippingOrBilling === "shipping" ? shippingCIF : billingCIF}
          onChange={(e) => {
            shippingOrBilling === "shipping"
              ? setShippingCIF(e.target.value)
              : setBillingCIF(e.target.value);
          }}
        ></Input>
        <br />
        <br />
      </div>
    );
  };
  const personalDetails = (shippingOrBilling) => {
    const isFirstNameEmpty =
      shippingOrBilling === "shipping"
        ? shippingFirstName === ""
        : billingFirstName === "";
    const isLastNameEmpty =
      shippingOrBilling === "shipping"
        ? shippingLastName === ""
        : billingLastName === "";
    const isEmailEmpty =
      shippingOrBilling === "shipping"
        ? shippingEmail === ""
        : billingEmail === "";
    const isPhoneEmpty =
      shippingOrBilling === "shipping"
        ? shippingMobilePhone === ""
        : billingMobilePhone === "";
    const isPhoneWrong =
      shippingOrBilling === "shipping"
        ? !romanianPhoneNumberRegexTest(shippingMobilePhone)
        : !romanianPhoneNumberRegexTest(billingMobilePhone);
    const isEmailWrong =
      shippingOrBilling === "shipping"
        ? !emailRegexTest(shippingEmail)
        : !emailRegexTest(billingEmail);

    return (
      <div>
        <br /> <br />
        <div>Nume*</div>
        <Input
          style={{
            border:
              validationError && isLastNameEmpty
                ? `1px solid ${PSRed}`
                : `1px solid ${PSDark}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSDark,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          type="text"
          id={`${shippingOrBilling}LastNameInput`}
          value={
            shippingOrBilling === "shipping"
              ? shippingLastName
              : billingLastName
          }
          onChange={(e) => {
            shippingOrBilling === "shipping"
              ? setShippingLastName(e.target.value)
              : setBillingLastName(e.target.value);
          }}
        ></Input>
        <br />
        <br />
        <div>Prenume*</div>
        <Input
          style={{
            border:
              validationError && isFirstNameEmpty
                ? `1px solid ${PSRed}`
                : `1px solid ${PSDark}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSDark,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          type="text"
          id={`${shippingOrBilling}FirstNameInput`}
          value={
            shippingOrBilling === "shipping"
              ? shippingFirstName
              : billingFirstName
          }
          onChange={(e) => {
            shippingOrBilling === "shipping"
              ? setShippingFirstName(e.target.value)
              : setBillingFirstName(e.target.value);
          }}
        ></Input>
        <br />
        <br />
        <div>Adresa de mail*</div>
        <Input
          style={{
            border:
              validationError && (isEmailEmpty || isEmailWrong)
                ? `1px solid ${PSRed}`
                : `1px solid ${PSDark}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSDark,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          type="text"
          id={`${shippingOrBilling}EmailInput`}
          // @ts-ignore
          value={
            // @ts-ignore
            getUserId() !== "guest"
              ? getUserId()
              : shippingOrBilling === "shipping"
              ? shippingEmail
              : billingEmail
          }
          // @ts-ignore
          readOnly={getUserId() === "guest" ? false : true}
          onChange={(e) => {
            // Only allow guest users to change email. Otherwise the logged in users
            // won't be able to see the order history.
            // @ts-ignore
            // if (getUserId() === "guest")
            shippingOrBilling === "shipping"
              ? setShippingEmail(e.target.value)
              : setBillingEmail(e.target.value);
          }}
        ></Input>
        <br />
        <br />
        <div>Număr de telefon*</div>
        <Input
          style={{
            border:
              validationError && (isPhoneEmpty || isPhoneWrong)
                ? `1px solid ${PSRed}`
                : `1px solid ${PSDark}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSDark,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          type="text"
          id={`${shippingOrBilling}PhoneInput`}
          value={
            shippingOrBilling === "shipping"
              ? shippingMobilePhone
              : billingMobilePhone
          }
          onChange={(e) => {
            shippingOrBilling === "shipping"
              ? setShippingMobilePhone(e.target.value)
              : setBillingMobilePhone(e.target.value);
          }}
        ></Input>
        <br />
        <br />
      </div>
    );
  };

  const shippingPageRender = () => {
    if (!checkEmptyDictionary(userCart)) {
      return (
        <div>
          <label htmlFor="sameInvoiceData">
            Datele de livrare sunt aceleași cu cele de facturare
          </label>
          <br />
          <input
            type="checkbox"
            id="sameInvoiceData"
            defaultChecked={true}
            onChange={(e) => {
              setSameInvoiceData(e.target.checked);
            }}
          ></input>
          <br />
          <br />
          <div className={classes.cartColumns}>
            <div className={classes.cartColumn}>
              {sameInvoiceData && (
                <div>
                  <h3>Date de livrare și facturare</h3>
                </div>
              )}
              {!sameInvoiceData && (
                <div>
                  <h3>Date de livrare</h3>
                </div>
              )}

              <div className={classes.cartColumns}>
                <div className={classes.shippingCartColumn}>
                  {personalDetails("shipping")}
                </div>
                <div className={classes.shippingCartColumn}>
                  {shippingDetails("shipping")}
                </div>
              </div>
              <ShippingSVG />
            </div>
            {!sameInvoiceData && (
              <div className={classes.cartColumn}>
                <h3>Date de facturare</h3>
                <div className={classes.cartColumns}>
                  <div className={classes.shippingCartColumn}>
                    {personalDetails("billing")}
                  </div>
                  <div className={classes.shippingCartColumn}>
                    {shippingDetails("billing")}
                  </div>
                </div>
                <ReceiptSVG></ReceiptSVG>
              </div>
            )}
          </div>
          <div className={classes.cartColumns}>
            <div className={classes.cartColumn}>
              <div>
                <h3>Modalitate de plată</h3>
              </div>

              <div className={classes.cartColumns}>
                <div
                  className={classes.shippingCartColumn}
                  style={{ padding: "1%" }}
                >
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: isMobile ? "55%" : "15%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <CreditCard></CreditCard>
                      <label>Plata online cu cardul</label>
                      <Divider orientation="vertical"></Divider>
                      <input
                        type="checkbox"
                        style={{ minWidth: "10%" }}
                        checked={paymentMethod === "card"}
                        onChange={(e) => {
                          e.target.value
                            ? setPaymentMethod("card")
                            : setPaymentMethod(undefined);
                        }}
                      />
                    </div>
                  </div>
                  <br />{" "}
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: isMobile ? "55%" : "15%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Cash></Cash>
                      <label htmlFor="paymentCash">
                        Plata numerar la livrare
                      </label>
                      <Divider orientation="vertical"></Divider>
                      <input
                        type="checkbox"
                        name="paymentCash"
                        style={{ minWidth: "10%" }}
                        checked={paymentMethod === "cash"}
                        onChange={(e) => {
                          e.target.value
                            ? setPaymentMethod("cash")
                            : setPaymentMethod(undefined);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div>
            Sunt de acord cu{" "}
            <a href="/TERMENI SI CONDITII.pdf" target="_new">
              Termenii și Condițiile
            </a>
          </div>
          <input
            type="checkbox"
            id="aggreeTerms"
            defaultChecked={false}
            onChange={(e) => {
              setAgreeTerms(e.target.checked);
            }}
          ></input>
          <br></br>
          <br></br>
          <div>
            {paymentMethod && paymentMethod === "cash" && (
              <>
                <div>
                  *Costul de procesare pentru comenzile cu plata la livrare este
                  de {cashOnDeliveryPrice} RON
                </div>
                <br />
              </>
            )}
            <Button
              variant="contained"
              style={{ color: "black" }}
              color="success"
              onClick={onClickCheckout}
              disabled={
                calculateTotalPrice() === 0 ||
                continueButtonDisabled ||
                !agreeTerms ||
                !paymentMethod
              }
            >
              {paymentMethod
                ? paymentMethod === "card"
                  ? "Continuă către plată"
                  : "Finalizează cu obligație de plată"
                : "Continuă către plată"}
            </Button>
          </div>
          <br></br>
          <br></br>
        </div>
      );
    } else {
      return (
        <div className={classes.cartMessage}>
          <div>
            Din păcate coșul tău este gol. Totuși, poți salva mereu momentul cu
            Vin Shop!
          </div>
          <br />
          <div>Mergi la magazin</div>
          <br />
          <button onClick={onClickContinueShopping}>Magazin</button>
          <br />
          <br />
        </div>
      );
    }
  };
  if (getUserId() === "waiting") {
    return <SpinnerBody />;
  }

  return (
    <main className={classes.content}>
      {!loadingPayment && (
        <div className={classes.cartBackground}>
          <div style={{ height: navbarHeight }} />
          <h2>Unde să îți livrăm produsele?</h2>
          <br></br>
          <br></br>

          {shippingPageRender()}
        </div>
      )}
      {loadingPayment && (
        <div className={classes.cartBackground}>
          <div style={{ height: navbarHeight, backgroundColor: "#0a0808" }} />
          <div className={classes.loaderBody}>
            <img src="../../logo192.avif" className={classes.loaderImage}></img>
            <br />
            <br />
            <div>
              {paymentMethod === "card"
                ? "Vei fi redirecționat către plată în câteva momente..."
                : paymentMethod === "cash"
                ? "Comanda ta este în procesare..."
                : "Ceva nu a mers bine"}
            </div>
          </div>
        </div>
      )}
    </main>
  );
};
