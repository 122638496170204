import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { CheckMarkIcon, FailedMarkIcon } from "../svgs/UtilSVGs";
import { Button, IconButton } from "@mui/material";
import { serverUrl } from "../../consts/serverUrl";
import { Phone } from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthProvider";

export const PaymentConfirmation = (props) => {
  const {
    setUserCart,
    setUserCartLineItems,
    setUserCartQtyItems,
    setIsRedirectingToPayment,
  } = useAuth();
  const { navbarHeight, setPreviousPage } = props;
  const [orderStatus, setOrderStatus] = useState("");
  const [checkedOrderStatus, setCheckedOrderStatus] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  window.scrollTo(0, 0);
  const { orderId } = useParams();
  const onClickHome = () => {
    navigate("/home");
  };

  useEffect(() => {
    setIsRedirectingToPayment(false);
  });

  useEffect(() => {
    setPreviousPage("");
  }, []);

  useEffect(() => {
    if (orderStatus === "successful") {
      localStorage.setItem("vinshop_user_cart", JSON.stringify({}));
      setUserCart({});
      setUserCartLineItems({});
      setUserCartQtyItems(0);
    }
  }, [orderStatus]);
  useEffect(() => {
    const checkOrderStatus = async () => {
      if (orderId === null) navigate("/home");
      else {
        const orderStatus = await fetch(`${serverUrl}/check-order/${orderId}`)
          .then((res) => res.json())
          .then((res) => res.orderStatus);
        if ((await orderStatus) !== "confirmata") {
          setOrderStatus("failed");
          setCheckedOrderStatus(true);
        } else {
          setOrderStatus("successful");
          setCheckedOrderStatus(true);
        }
      }
    };
    checkOrderStatus();
  }, []);

  return (
    <main className={classes.centeredContent}>
      <div className={classes.navHolder}></div>
      <div className={classes.pageTextColor}>
        {!checkedOrderStatus && (
          <div>
            <div style={{ height: navbarHeight, backgroundColor: "#0a0808" }} />
            <div className={classes.loaderBody}>
              <img
                src="../../logo192.avif"
                className={classes.loaderImage}
              ></img>
              <br />
              <br />
              <div>Verificăm comanda ta...</div>
            </div>
          </div>
        )}
        {checkedOrderStatus && orderStatus === "successful" && (
          <div>
            <div style={{ height: navbarHeight }}></div>
            <h1>Comandă confirmată</h1>
            <br />
            <br />
            <h2>{`Comanda ta cu codul ${orderId} a fost înregistrată și va fi procesată în cel mai scurt timp posibil!`}</h2>
            <br />
            <br />
            <div className={classes.iconHolder}>
              <CheckMarkIcon />
            </div>
            <br />
            <br />
            <div>
              În scurt timp vei primi un mail de confirmare. Te mai așteptăm pe
              la noi!
            </div>
            <br />
            <br />
            <Button
              variant="contained"
              style={{ backgroundColor: "#bcab79", color: "#0a0808" }}
              onClick={onClickHome}
            >
              Mergi la pagina principală
            </Button>
            <br />
            <br />
          </div>
        )}
        {checkedOrderStatus && orderStatus === "failed" && (
          <div>
            <div style={{ height: navbarHeight }}></div>
            <h1>Comandă eșuată</h1>
            <br />
            <br />
            <h2>{`Din păcate comanda ta cu codul ${orderId} nu a fost finalizată cu succes. Încreacă din nou sau contactează-ne, iar noi te vom ajuta cu plasarea comenzii tale!`}</h2>
            <br />
            <br />
            <div className={classes.iconHolder}>
              <FailedMarkIcon />
            </div>
            <br />
            <br />
            <Button
              variant="contained"
              style={{ backgroundColor: "#bcab79", color: "#0a0808" }}
            >
              <IconButton>
                <Phone></Phone>
              </IconButton>{" "}
              <a
                href="tel: +40 745 629 362"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                Contactează-ne
              </a>
            </Button>
            <br />
            <br />
            <Button
              variant="contained"
              style={{ backgroundColor: "#bcab79", color: "#0a0808" }}
              onClick={onClickHome}
            >
              Mergi la pagina principală
            </Button>
            <br />
            <br />
          </div>
        )}
      </div>
    </main>
  );
};
