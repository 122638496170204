// FIXME: Replace product parameter with product id
export const onClickAddToCart = (
  product,
  userId,
  cartLineItems,
  cartQtyItems,
  setCartLineItems,
  setCartQtyItems,
  cart,
  setCart,
  quantityToAdd = 1
) => {
  const parsedCartQtyItems = parseInt(cartQtyItems) || 0;
  const cartQtyItemsLocal = parsedCartQtyItems + quantityToAdd;

  const cartLineItemsLocal = {
    ...cartLineItems,
    [product.productId]:
      (cartLineItems[product.productId] || 0) + quantityToAdd,
  };

  const localCart = {
    ...cart,
    [userId]: {
      ...cart[userId],
      line_items: cartLineItemsLocal,
      qty_items: cartQtyItemsLocal,
    },
  };

  setCartLineItems(cartLineItemsLocal);
  setCart(localCart);
  setCartQtyItems(cartQtyItemsLocal);
  localStorage.setItem("vinshop_user_cart", JSON.stringify(localCart));
};

export const onChangeUpdateItemQty = (
  product,
  newItemQty,
  userId,
  cartLineItems,
  cartQtyItems,
  setCartLineItems,
  setCartQtyItems,
  cart,
  setCart
) => {
  newItemQty = newItemQty ? parseInt(newItemQty) : 0;
  let cartQtyItemsLocal = cartQtyItems ? parseInt(cartQtyItems) : 0;

  // Create shallow copies of the line items and cart
  let cartLineItemsLocal = { ...cartLineItems };
  let localCart = { ...cart }; // Make a shallow copy of the cart

  if (cartLineItemsLocal.hasOwnProperty(product.productId)) {
    const itemQty = cartLineItemsLocal[product.productId];
    const baseQty = cartQtyItemsLocal - itemQty;

    // Update the line item quantity
    cartLineItemsLocal[product.productId] = newItemQty;
    const updatedQty = baseQty + newItemQty;

    // Update the local cart
    localCart[userId] = {
      ...localCart[userId],
      line_items: cartLineItemsLocal,
      qty_items: updatedQty,
    };

    // Set the new state
    setCartLineItems(cartLineItemsLocal);
    setCart(localCart);
    setCartQtyItems(updatedQty);

    // Save the updated cart to local storage
    localStorage.setItem("vinshop_user_cart", JSON.stringify(localCart));
  }
};

export const onClickRemoveItemFromCart = (
  product,
  userId,
  cartLineItems,
  cartQtyItems,
  setCartLineItems,
  setCartQtyItems,
  cart,
  setCart
) => {
  let cartQtyItemsLocal = cartQtyItems ? parseInt(cartQtyItems) : 0;
  let deletedQty = 0;

  // Create shallow copies of the line items and cart
  let cartLineItemsLocal = { ...cartLineItems };
  let localCart = { ...cart }; // Make a shallow copy of the cart

  if (cartLineItemsLocal.hasOwnProperty(product.productId)) {
    deletedQty = cartLineItemsLocal[product.productId]
      ? parseInt(cartLineItemsLocal[product.productId])
      : 0;

    // Delete the product from the local line items
    delete cartLineItemsLocal[product.productId];

    // Update the quantity items
    cartQtyItemsLocal -= deletedQty;
  }

  // Update the local cart
  localCart[userId] = {
    ...localCart[userId],
    line_items: cartLineItemsLocal,
    qty_items: cartQtyItemsLocal,
  };

  // Set the new state
  setCartLineItems(cartLineItemsLocal);
  setCart(localCart);
  setCartQtyItems(cartQtyItemsLocal);

  // Save the updated cart to local storage
  localStorage.setItem("vinshop_user_cart", JSON.stringify(localCart));
};

export const retrieveUserInfo = () => {
  const savedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (savedUserInfo) {
    const now = Date.now();
    const comparableExpireDate = Date.parse(savedUserInfo.expires);
    if (comparableExpireDate <= now) {
      if (!savedUserInfo.rememberMe) return {};
    }
    return savedUserInfo;
  } else {
    return {};
  }
};

export const emailRegexTest = (checkedValue) => {
  // Email validation regex pattern
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return emailRegex.test(checkedValue);
};

export const romanianPhoneNumberRegexTest = (checkedValue) => {
  // Romanian phone number validation regex pattern
  const phoneRegex = /^(?:\+40|0)[7236]\d{8}$/;

  return phoneRegex.test(checkedValue);
};

export const romanianCNPRegexTest = (checkedValue) => {
  // Romanian CNP regex pattern
  const cnpRegex =
    /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/;

  return cnpRegex.test(checkedValue);
};

export const validateCNP = (cnp) => {
  const constant = "279146358279";
  let sum = 0;

  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnp.charAt(i)) * parseInt(constant.charAt(i));
  }

  const remainder = sum % 11;
  let controlDigit = 0;

  if (remainder < 10) {
    controlDigit = remainder;
  } else {
    controlDigit = 1;
  }

  return controlDigit === parseInt(cnp.charAt(12));
};

export const isOver18 = (cnp) => {
  // Obține prima cifră din CNP
  const firstDigit = parseInt(cnp.charAt(0));

  // Dacă prima cifră este mai mică decât 5, returnează true
  if (firstDigit < 5) {
    return true;
  }

  // Obține data de naștere din CNP
  const yearLastDigits = parseInt(cnp.substr(1, 2));
  const year = 2000 + yearLastDigits;
  const month = parseInt(cnp.substr(3, 2));
  const day = parseInt(cnp.substr(5, 2));

  // Obține data actuală
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear(); // actual year
  const currentMonth = currentDate.getMonth() + 1; // lunile sunt indexate de la 0 la 11
  const currentDay = currentDate.getDate();

  // Calculează vârsta
  let age = currentYear - year;
  if (currentMonth < month || (currentMonth === month && currentDay < day)) {
    age--; // dacă nu a împlinit încă aniversarea în acest an
  }

  // Verifică dacă vârsta este mai mare sau egală cu 18 ani
  return age >= 18;
};

export const calculateProductRating = (/** @type {any[]} */ ratings) => {
  let ratingsSum = 0;
  let ratingsCount = 0;
  for (const rating of ratings) {
    ratingsSum += rating.review.score;
    ratingsCount++;
  }
  return (ratingsSum / ratingsCount).toFixed(1);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
