import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { serverUrl } from "../../consts/serverUrl";
import { useNavigate } from "react-router-dom";
import { checkEmptyDictionary } from "../../consts/helpers";
import {
  onChangeUpdateItemQty,
  onClickRemoveItemFromCart,
} from "../../consts/functions";
import { LocalShipping } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";
import { shippingPrice, shippingThreshold } from "../../consts/shipping";
import { uploadUrl } from "../../consts/uploadsUrl";
import { CheckMarkPromotionCode } from "../svgs/UtilSVGs";
import { useAuth } from "../../contexts/AuthProvider";
import { SpinnerBody } from "../Spinner/Spinner";
import { PSDark, PSGold } from "../../consts/styleConsts";

/**
 * Utility function to find a product in the list of all products. If the
 * product is not found, return an empty string.
 * @param {*} productId
 * @param {*} allProducts
 * @returns product object or empty string
 */
export const findProduct = (productId, allProducts) => {
  if (allProducts.length > 0) {
    return allProducts.find((product) => product.productId === productId);
  } else return "";
};

export const Cart = (props) => {
  const {
    userCart,
    setUserCart,
    userCartLineItems,
    setUserCartLineItems,
    userCartQtyItems,
    setUserCartQtyItems,
    getUserId,
  } = useAuth();
  const {
    page,
    setPage,
    products,
    setSelectedProduct,
    navbarHeight,
    discountPercentage,
    setDiscountPercentage,
    setPreviousPage,
  } = props;

  const navigate = useNavigate();
  const classes = useStyles();
  const [promotionCode, setPromotionCode] = useState("");
  const [checkedPromotionCode, setCheckedPromotionCode] = useState("");
  const [promotionChecked, setPromotionChecked] = useState(false);
  const [validPromotion, setValidPromotion] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const handlePromotionCodeChange = (event) => {
    setPromotionCode(event.target.value);
  };

  const validatePromotionCode = async (code) => {
    setPromotionChecked(true);
    setCheckedPromotionCode(code);

    let promotionCheckResult = await fetch(
      `${serverUrl}/check-promotion/${code.toLowerCase()}`
    ).then((res) => res.json());

    if (promotionCheckResult.message === "Promotion not found") {
      setValidPromotion(false);
      setDiscountPercentage(0);
    } else if (promotionCheckResult) {
      if (promotionCheckResult.promotion.expirationDate !== 0) {
        if (promotionCheckResult.promotion.expirationDate > new Date()) {
          setValidPromotion(true);
          let discountPercentage =
            promotionCheckResult.promotion.discountPercentage;
          setDiscountPercentage(discountPercentage);
        } else {
          setValidPromotion(false);
          setDiscountPercentage(0);
          alert("Codul de reducere a expirat!");
        }
      } else {
        setValidPromotion(true);
        let discountPercentage =
          promotionCheckResult.promotion.discountPercentage;
        setDiscountPercentage(discountPercentage);
      }
    }
  };

  const onClickContinueShopping = () => {
    setPage("Shop");
    navigate("/shop");
  };

  const onClickProduct = (productId) => {
    setPage("ProductPage");
    const clickedProduct = findProduct(productId, products);
    setSelectedProduct(clickedProduct);
    navigate(`/produs/${encodeURIComponent(clickedProduct.name)}`);
  };

  const onClickCheckout = () => {
    setPage("Shipping");
    navigate("shipping");
  };

  const calculateShippingPrice = (orderPrice) => {
    if (orderPrice < shippingThreshold) return shippingPrice;
    else return 0;
  };

  const calculateTotalPrice = () => {
    // Check if the cart or line_items are empty
    if (
      checkEmptyDictionary(userCart) ||
      checkEmptyDictionary(userCart[getUserId()].line_items)
    )
      return 0;

    // Use reduce to calculate the total price
    return Object.keys(userCart[getUserId()].line_items).reduce(
      (totalPrice, productId) => {
        const product = findProduct(productId, products);

        // Calculate price based on discount or regular price
        const productPrice = product
          ? product.discount
            ? (parseFloat(product.price) *
                (100 - parseFloat(product.discount))) /
              100
            : parseFloat(product.price)
          : 0;

        // Add to total price by multiplying by the quantity
        return (
          totalPrice +
          productPrice * userCart[getUserId()].line_items[productId]
        );
      },
      0 // Initial value of totalPrice
    );
  };

  const calculateOriginalPrice = () => {
    let totalPriceLocal = 0;
    if (!checkEmptyDictionary(userCart))
      Object.keys(userCart[getUserId()].line_items ?? {}).map((productId) => {
        totalPriceLocal +=
          (findProduct(productId, products)
            ? parseFloat(findProduct(productId, products).price)
            : 0) * userCart[getUserId()].line_items[productId];
      });

    return totalPriceLocal;
  };

  useEffect(() => {
    setPreviousPage("");
  }, []);

  const cartPageRender = () => {
    if (!checkEmptyDictionary(userCart)) {
      if (
        !checkEmptyDictionary(userCart[Object.keys(userCart)[0]].line_items)
      ) {
        return (
          <div>
            {!isMobile && (
              <div className={classes.cartColumns}>
                <h3 className={classes.cartColumn}>Produs</h3>
                <h3 className={classes.cartColumn}>Denumire</h3>
                <h3 className={classes.cartColumn}>Cod</h3>
                <h3 className={classes.cartColumn}>Cantitate</h3>
                <h3 className={classes.cartColumn}>Preț unitar fără TVA</h3>
                <h3 className={classes.cartColumn}>TVA unitară</h3>
                <h3 className={classes.cartColumn}>Preț unitar cu TVA</h3>
                <h3 className={classes.cartColumn}>Subtotal</h3>
                <h3 className={classes.cartColumn}>Elimină</h3>
              </div>
            )}
            {Object.keys(userCart[getUserId()]?.line_items).map((productId) => (
              <div className={classes.cartColumns} key={productId}>
                <div key={productId} className={classes.cartColumn}>
                  <Card className={classes.root}>
                    <CardContent>
                      <div>
                        <div className={classes.cartColumns}>
                          <div className={classes.cartColumn}>
                            <img
                              onClick={() => onClickProduct(productId)}
                              src={
                                findProduct(productId, products)
                                  ? findProduct(productId, products).images
                                    ? `${uploadUrl}${
                                        findProduct(productId, products)
                                          .images[0]
                                      }`
                                    : ""
                                  : ""
                              }
                              style={{ width: "10vw" }}
                              alt="Product"
                            />
                          </div>
                          <div className={classes.cartColumn}>
                            {isMobile && <h4>Denumire</h4>}
                            <div onClick={() => onClickProduct(productId)}>
                              {findProduct(productId, products)
                                ? findProduct(productId, products).name
                                : ""}
                            </div>
                          </div>
                          <br />{" "}
                          <div className={classes.cartColumn}>
                            {isMobile && <h4>Cod produs</h4>}
                            <div>
                              {findProduct(productId, products)
                                ? findProduct(productId, products).productId
                                : ""}
                            </div>
                          </div>
                          <div className={classes.cartColumn}>
                            {isMobile && <h4>Cantitate</h4>}
                            <Typography>
                              <input
                                type="number"
                                className={classes.qtyInput}
                                defaultValue={
                                  userCart[getUserId()].line_items[productId]
                                }
                                min={1}
                                max={
                                  findProduct(productId, products)
                                    ? findProduct(productId, products).sku
                                    : 0
                                }
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);
                                  if (value < 1) e.target.value = "1";
                                  onChangeUpdateItemQty(
                                    findProduct(productId, products),
                                    value > 1 ? value : 1,
                                    getUserId(),
                                    userCartLineItems,
                                    userCartQtyItems,
                                    setUserCartLineItems,
                                    setUserCartQtyItems,
                                    userCart,
                                    setUserCart
                                  );
                                }}
                              />{" "}
                              Buc.
                            </Typography>
                          </div>
                          <div className={classes.cartColumn}>
                            {isMobile && <h4>Preț unitar fără TVA</h4>}
                            <Typography>
                              {(
                                ((findProduct(productId, products)
                                  ? findProduct(productId, products).discount
                                    ? (parseFloat(
                                        findProduct(productId, products).price
                                      ) *
                                        (100.0 -
                                          parseFloat(
                                            findProduct(productId, products)
                                              .discount
                                          ))) /
                                      100.0
                                    : parseFloat(
                                        findProduct(productId, products).price
                                      )
                                  : 0) *
                                  100.0) /
                                119.0
                              ).toFixed(2)}{" "}
                              RON
                            </Typography>
                          </div>
                          <div className={classes.cartColumn}>
                            {isMobile && <h4>TVA unitară</h4>}
                            <Typography>
                              {(
                                ((findProduct(productId, products)
                                  ? findProduct(productId, products).discount
                                    ? (parseFloat(
                                        findProduct(productId, products).price
                                      ) *
                                        (100.0 -
                                          parseFloat(
                                            findProduct(productId, products)
                                              .discount
                                          ))) /
                                      100.0
                                    : parseFloat(
                                        findProduct(productId, products).price
                                      )
                                  : 0) *
                                  19.0) /
                                119.0
                              ).toFixed(2)}{" "}
                              RON
                            </Typography>
                          </div>
                          <div className={classes.cartColumn}>
                            {isMobile && <h4>Preț unitar cu TVA</h4>}
                            {findProduct(productId, products).discount ? (
                              <div>
                                <div
                                  style={{
                                    textDecoration: "line-through",
                                    color: "#990000",
                                  }}
                                >
                                  <Typography>
                                    {findProduct(productId, products)
                                      ? parseFloat(
                                          findProduct(productId, products).price
                                        )
                                      : (0).toFixed(2)}{" "}
                                    RON
                                  </Typography>
                                </div>
                                <div>
                                  <Typography>
                                    {findProduct(productId, products)
                                      ? findProduct(productId, products)
                                          .discount
                                        ? (
                                            (parseFloat(
                                              findProduct(productId, products)
                                                .price
                                            ) *
                                              (100.0 -
                                                parseFloat(
                                                  findProduct(
                                                    productId,
                                                    products
                                                  ).discount
                                                ))) /
                                            100.0
                                          ).toFixed(2)
                                        : parseFloat(
                                            findProduct(productId, products)
                                              .price
                                          )
                                      : (0).toFixed(2)}{" "}
                                    RON
                                  </Typography>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <Typography>
                                  {findProduct(productId, products)
                                    ? findProduct(productId, products).discount
                                      ? (
                                          (parseFloat(
                                            findProduct(productId, products)
                                              .price
                                          ) *
                                            (100.0 -
                                              parseFloat(
                                                findProduct(productId, products)
                                                  .discount
                                              ))) /
                                          100.0
                                        ).toFixed(2)
                                      : parseFloat(
                                          findProduct(productId, products).price
                                        )
                                    : (0).toFixed(2)}{" "}
                                  RON
                                </Typography>
                              </div>
                            )}
                          </div>
                          <div className={classes.cartColumn}>
                            {isMobile && <h4>Subtotal</h4>}
                            {findProduct(productId, products).discount ? (
                              <div>
                                <Typography>
                                  <div
                                    style={{
                                      textDecoration: "line-through",
                                      color: "#990000",
                                    }}
                                  >
                                    {(
                                      userCart[getUserId()].line_items[
                                        productId
                                      ] *
                                      parseFloat(
                                        findProduct(productId, products)
                                          ? findProduct(productId, products)
                                              .price
                                          : 0
                                      )
                                    ).toFixed(2)}{" "}
                                    RON
                                  </div>
                                </Typography>
                                <Typography>
                                  {(
                                    userCart[getUserId()].line_items[
                                      productId
                                    ] *
                                    parseFloat(
                                      findProduct(productId, products)
                                        ? findProduct(productId, products)
                                            .discount
                                          ? (
                                              (parseFloat(
                                                findProduct(productId, products)
                                                  .price
                                              ) *
                                                (100.0 -
                                                  parseFloat(
                                                    findProduct(
                                                      productId,
                                                      products
                                                    ).discount
                                                  ))) /
                                              100.0
                                            ).toFixed(2)
                                          : findProduct(productId, products)
                                              .price
                                        : 0
                                    )
                                  ).toFixed(2)}{" "}
                                  RON
                                </Typography>
                              </div>
                            ) : (
                              <Typography>
                                {(
                                  userCart[getUserId()].line_items[productId] *
                                  parseFloat(
                                    findProduct(productId, products)
                                      ? findProduct(productId, products)
                                          .discount
                                        ? (
                                            (parseFloat(
                                              findProduct(productId, products)
                                                .price
                                            ) *
                                              (100.0 -
                                                parseFloat(
                                                  findProduct(
                                                    productId,
                                                    products
                                                  ).discount
                                                ))) /
                                            100.0
                                          ).toFixed(2)
                                        : findProduct(productId, products).price
                                      : 0
                                  )
                                ).toFixed(2)}{" "}
                                RON
                              </Typography>
                            )}
                          </div>
                          <div className={classes.cartColumn}>
                            <div style={{ margin: "auto" }}>
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  onClickRemoveItemFromCart(
                                    findProduct(productId, products),
                                    getUserId(),
                                    userCartLineItems,
                                    userCartQtyItems,
                                    setUserCartLineItems,
                                    setUserCartQtyItems,
                                    userCart,
                                    setUserCart
                                  )
                                }
                              >
                                <DeleteIcon
                                  style={{ color: "black" }}
                                ></DeleteIcon>
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            ))}
            {/* BONUS PRODUCT */}
            {/* {calculateTotalPrice() >= 200 && (
              <BonusProduct
                productIds={["10515"]}
                products={products}
                bonusQty={1}
              />
            )} */}
            <h4>Subtotal</h4>
            {calculateTotalPrice() < calculateOriginalPrice() ? (
              <div>
                <div
                  style={{
                    textDecoration: "line-through",
                    color: "#990000",
                  }}
                >
                  {calculateOriginalPrice().toFixed(2)} RON (incl. TVA)
                </div>
                <div>{calculateTotalPrice().toFixed(2)} RON (incl. TVA)</div>
              </div>
            ) : (
              <div>{calculateTotalPrice().toFixed(2)} RON (incl. TVA)</div>
            )}
            <h4>Preț transport</h4>
            <div>
              {calculateShippingPrice(
                (calculateTotalPrice() * (100 - discountPercentage)) / 100
              ) !== 0 && (
                <div>
                  <div>{`${calculateShippingPrice(
                    (calculateTotalPrice() * (100 - discountPercentage)) / 100
                  ).toFixed(2)} RON`}</div>
                  <br />
                  <div>{`Comandă produse de încă ${(
                    shippingThreshold -
                    (calculateTotalPrice() * (100 - discountPercentage)) / 100
                  ).toFixed(2)} RON și beneficiezi de transport gratuit!`}</div>
                </div>
              )}
              {calculateShippingPrice(
                (calculateTotalPrice() * (100 - discountPercentage)) / 100
              ) === 0 && <div>{`Transportul este inclus în preț`}</div>}
            </div>
            <h2>Total</h2>
            <h3
              style={{
                textDecoration:
                  validPromotion && discountPercentage > 0
                    ? "line-through"
                    : "none",
                color:
                  validPromotion && discountPercentage > 0
                    ? "#880808"
                    : "black",
              }}
            >
              {(
                calculateTotalPrice() +
                calculateShippingPrice(calculateTotalPrice())
              ).toFixed(2)}{" "}
              RON (incl. TVA și transport)
            </h3>
            {validPromotion && discountPercentage > 0 && (
              <div>
                -{" "}
                {((calculateTotalPrice() * discountPercentage) / 100).toFixed(
                  2
                )}{" "}
                RON Reducere (cod: {checkedPromotionCode})
              </div>
            )}
            {validPromotion && discountPercentage > 0 && (
              <div>
                <h2>Preț final</h2>
                <h3 style={{ color: "#2e7d32" }}>
                  {(
                    (calculateTotalPrice() * (100 - discountPercentage)) / 100 +
                    calculateShippingPrice(
                      (calculateTotalPrice() * (100 - discountPercentage)) / 100
                    )
                  ).toFixed(2)}{" "}
                  RON (incl. TVA și transport)
                </h3>
              </div>
            )}

            <div>
              <LocalShipping></LocalShipping>
              <Typography>
                Asigurăm livrarea gratuită pentru comenzile de cel puțin{" "}
                {shippingThreshold} RON
              </Typography>
            </div>
            <br />
            <br />
            <input
              type="text"
              id="promotionCode"
              placeholder="Cod promoțional"
              className={`${classes.inputMailNewsletter} ${
                promotionCode !== "" ? "valid" : "invalid"
              }`}
              onChange={handlePromotionCodeChange}
            ></input>
            {validPromotion ? (
              <div>
                <CheckMarkPromotionCode />
              </div>
            ) : promotionChecked === true ? (
              <div>
                <span style={{ color: "#880808" }}>
                  Codul introdus este invalid!
                </span>
              </div>
            ) : (
              <div></div>
            )}
            <br />
            <Button
              variant="contained"
              style={{
                backgroundColor: PSGold,
                color: PSDark,
              }}
              onClick={() => validatePromotionCode(promotionCode)}
            >
              Validare cod
            </Button>
            <br />
            <br />
            <br />
            <Button
              variant="contained"
              style={{ color: "black" }}
              color="success"
              onClick={onClickCheckout}
              disabled={calculateTotalPrice() === 0}
            >
              Continuă către livrare și plată
            </Button>
            <br />
            <br />
            <Button
              variant="contained"
              style={{ backgroundColor: PSDark, color: PSGold }}
              onClick={onClickContinueShopping}
            >
              Înapoi la magazin
            </Button>
            <div>
              <br />
              <br />
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.cartMessage}>
            <div>
              Din păcate coșul tău este gol. Totuși, poți salva mereu momentul
              cu Vin Shop!
            </div>
            <br />
            <div>Mergi la magazin</div>
            <br />
            <Button
              variant="contained"
              style={{ backgroundColor: PSDark, color: PSGold }}
              onClick={onClickContinueShopping}
            >
              Mergi la magazin
            </Button>
            <br />
            <br />
          </div>
        );
      }
    } else {
      return (
        <div className={classes.cartMessage}>
          <div>
            Din păcate coșul tău este gol. Totuși, poți salva mereu momentul cu
            Vin Shop!
          </div>
          <br />
          <Button
            variant="contained"
            style={{ backgroundColor: PSDark, color: PSGold }}
            onClick={onClickContinueShopping}
          >
            Mergi la magazin
          </Button>
          <br />
          <br />
        </div>
      );
    }
  };

  // @ts-ignore
  if (getUserId() === "waiting") return <SpinnerBody message="" />;

  return (
    <main className={classes.content}>
      <div className={classes.cartBackground}>
        <div style={{ height: navbarHeight }} />
        <h2>Coșul Tău</h2>
        <br></br>
        {cartPageRender()}
      </div>
    </main>
  );
};
